import {EnvStore} from "~/common-store/env-store";
import uuid from "~/utils/uuid";
import {I18nStore} from "~/common-store/i18n-store";
import {LangStore} from "~/common-store/lang-store";
import {CurrencyStore} from "~/common-store/currency";

export default defineNuxtRouteMiddleware(async (to, from) => {
    console.log(uuid.id(4));
    console.log(uuid.id());

    const envStore = EnvStore();
    envStore.loadConfig()

    //加载 i18n文件
    const i18nStore = I18nStore();
    await i18nStore.loadI18n();

    //加载 语言
    const langStore = LangStore();
    await langStore.loadLangInfoFromStorage();

    // 加载币种信息
    const currencyStore = CurrencyStore();
    await currencyStore.fetchCurrencyList();

})
